<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt @click="$router.push('/')"/>
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~" v-model="valueList" />
            <button @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt />
            <span>热门游戏</span>
             <div class="newClass">
              <hotlist></hotlist>
            </div>
          </div>
          <ul>
            <li :class="{ active: isShows == 0 }" @click="toClick(0)">首页</li>
            <li :class="{ active: isShows == 1 }" @click="toClick(1)">
              爱租号
            </li>
            <li :class="{ active: isShows == 2 }" @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="member">
      <div class="member_box w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>联系我们</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="introd_h">
            <h3>联系我们</h3>
            <p>发布时间:2020-09.09 13:22 浏览量：354687146</p>
        </div>
        <img class="logo" src="@/assets/contact/dt.png" alt="" />
        <div class="introd_wd">
          <ul>
              <li>武汉我天天科技有限公司  </li>
              <li>如有侵权请联系邮箱：tumali@dianzhi.cn </li>
              <li>网吧类业务/接口合作</li>
              <li>联系电话：87102722</li>
              <li>市场合作：tumali@dianzhi.cn</li>
              <li>业务洽谈联系QQ：8837506 </li>
              <li>投诉相关请联系邮箱：tumali@dianzhi.cn</li>
              <li>商务合作：tumali@dianzhi.cn</li>
              <li>广告业务联系QQ：8837506</li>
          </ul>
        </div>
      </div>
    </div>
    <side-Bar></side-Bar>
  </layout>
</template>

<script>
import layout from "../../components/layout";
import sideBar from "../../components/common/sidebar";
import hotlist from '../../components/hotlist'

export default {
  components: {
    layout,
    sideBar,
    hotlist
  },
  data() {
    return {
      isShows: 1,
      valueList: "",
    };
  },
  methods: {
    toClick(id) {
      if (id == 0) {
        this.$router.push({
          path: "/home",
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/download",
        });
      } else {
        this.$router.push({
          path: "/lobby",
        });
      }
      this.isShow = id;
    },
    goSerach() {
      if (this.valueList) {
        this.$router.push({
          path: "/lobby",
          query: {
            key_word: this.valueList,
          },
        });
      }
    },
  },
};
</script>

<style  lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
          cursor: pointer;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
          position: relative;
          cursor: pointer;
        &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
        .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
         .active{
                   color: #000;
                   font-weight: bold;
                 }
        .active:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
.w {
  width: 1200px;
  margin: 0 auto;
}
.member {
  width: 100%;
  background-color: #f6f7fa;
  padding-bottom: 110px;
  .member_box {
    .el-breadcrumb {
      padding: 20px 0;
      border-bottom: 1px solid #e6e6e6;
    }
  }
  .introd_h {
      text-align: center;
      padding: 55px 0 60px 0;
    h3{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    p{
        font-size: 12px;
        color: #A1A6B7;
    }
  }
  .logo {
    width: 100%;
    height: 420px;
  }
  .introd_wd {
    ul{
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
          width: 33%;
          font-size: 13px;
          color: #83899D;
          margin-bottom: 15px;
        }
    }
  }
  
}
</style>